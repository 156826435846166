<template>
  <div class="availability-general-sidebar">
    <div class="availability-general-sidebar--content">
      <p
        v-if="companies.length === 1"
        style="font-size: 14px; font-weight: 600"
      >
        {{ companies[0].name }}
      </p>
      <div
        v-if="overbookedVehicles"
        class="availability-subsection--subheader"
        style="padding-bottom: 10px"
      >
        <CRIcon
          :key="`overbooked-alert-icon-${overbookedVehicles}`"
          view-box="0 0 24 24"
          :width="16"
          :height="16"
          color="red"
        >
          alert_outline
        </CRIcon>
        <p class="overbooked-text">
          More vehicles required than availabile (+{{ overbookedVehicles }})
        </p>
      </div>
      <div v-if="isVehicleTypeGeneralBlock && notOfferedVehicles">
        <div
          class="availability-header justify-space-between"
          style="display: flex"
          :style="!notOfferedHidden ? 'margin-bottom: 0' : ''"
        >
          <div style="display: flex">
            <CRIcon
              :key="`available-vehicles-sidebar-icon-${notOfferedVehicles}`"
              view-box="0 0 32 32"
              :width="20"
              :height="20"
              color="red"
            >
              affiliate_management
            </CRIcon>
            <p class="availability-header--text">
              {{ notOfferedVehicles }} Vehicle Not Offered
            </p>
          </div>
          <div>
            <CRIcon
              :key="
                notOfferedHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              "
              view-box="0 0 24 24"
              :width="24"
              :height="24"
              color="gray"
              class="arrow-icon"
              @click.native="notOfferedHidden = !notOfferedHidden"
            >
              {{
                notOfferedHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }}
            </CRIcon>
          </div>
        </div>
        <div v-show="!notOfferedHidden">
          <div
            v-if="notOfferedReservations.length"
            class="availability-subsection"
          >
            <div
              v-for="notOfferedReservation in notOfferedReservations"
              :key="`not-offered-reservation-${notOfferedReservation.reservationId}`"
              class="availability-subsection--reservation"
              style="padding-left: 30px !important"
            >
              <div>
                <p class="availability-subsection--reservation--header">
                  ID
                  <router-link
                    :id="`reservation-link-${notOfferedReservation.reservationId}`"
                    style="text-decoration: none"
                    target="_blank"
                    :to="{
                      name: 'reservation-detail',
                      params: { id: notOfferedReservation.reservationId },
                    }"
                  >
                    #{{ notOfferedReservation.managedReservationId }}
                  </router-link>
                </p>
                <p>
                  Pickup Location: {{ notOfferedReservation.pickupLocation }}
                </p>
                <p>Pickup Time: {{ notOfferedReservation.pickupTime }}</p>
              </div>
              <p style="padding-top: 5px">
                {{ calculateRequiredVehicles(notOfferedReservation) }}
                Required
              </p>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <div v-if="availableVehicles" class="availability-header">
        <CRIcon
          :key="`available-vehicles-sidebar-icon-${availableVehicles}`"
          view-box="0 0 32 32"
          :width="20"
          :height="20"
          color="green"
        >
          vehicles
        </CRIcon>
        <p class="availability-header--text">
          {{ availableVehicles }} Available
        </p>
      </div>
      <div
        v-if="unavailableVehicles"
        class="availability-header justify-space-between"
        style="display: flex"
      >
        <div style="display: flex">
          <CRIcon
            :key="`unavailable-vehicles-sidebar-icon-${unavailableVehicles}`"
            view-box="0 0 32 32"
            :width="20"
            :height="20"
            color="red"
          >
            vehicles
          </CRIcon>
          <p class="availability-header--text">
            {{ unavailableVehicles }} Unavailable
          </p>
        </div>
        <div>
          <CRIcon
            :key="
              unavailableHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
            "
            view-box="0 0 24 24"
            :width="24"
            :height="24"
            color="gray"
            class="arrow-icon"
            @click.native="unavailableHidden = !unavailableHidden"
          >
            {{
              unavailableHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
            }}
          </CRIcon>
        </div>
      </div>
      <div v-show="!unavailableHidden">
        <div v-if="offeredReservations.length" class="availability-subsection">
          <div
            class="availability-subsection--subheader justify-space-between"
            style="padding-bottom: 10px; display: flex"
          >
            <div style="display: flex">
              <CRIcon
                :key="`alert-outline-icon`"
                view-box="0 0 24 24"
                :width="16"
                :height="16"
              >
                alert_outline
              </CRIcon>
              <p class="availability-subsection--subheader--text">
                Offered ({{ offeredReservationsVehicleCount }})
              </p>
            </div>
            <div>
              <CRIcon
                :key="
                  offeredReservationHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                "
                view-box="0 0 24 24"
                :width="24"
                :height="24"
                color="gray"
                class="arrow-icon"
                @click.native="offeredReservationHidden = !offeredReservationHidden"
              >
                {{
                  offeredReservationHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                }}
              </CRIcon>
            </div>
          </div>
          <div v-show="!offeredReservationHidden">
            <div
              v-for="offeredReservation in sortedOfferedReservations"
              :key="`offered-reservation-${offeredReservation.reservationId}`"
              class="availability-subsection--reservation"
            >
              <div>
                <p class="availability-subsection--reservation--header">
                  ID
                  <router-link
                    v-if="offeredReservation.parentReservationId"
                    :id="`reservation-link-${offeredReservation.reservationId}`"
                    style="text-decoration: none"
                    target="_blank"
                    :to="{
                      name: 'reservation-detail',
                      params: { id: offeredReservation.parentReservationId },
                    }"
                  >
                    #{{ offeredReservation.managedReservationId }}
                  </router-link>
                  <span v-else>
                    #{{ offeredReservation.managedReservationId }}
                  </span>
                </p>
                <p v-if="isVehicleTypeGeneralBlock" style="font-weight: 600">
                  {{ offeredReservation.companyName }}
                </p>
                <p>Pickup Location: {{ offeredReservation.pickupLocation }}</p>
                <p>Pickup Time: {{ offeredReservation.pickupTime }}</p>
              </div>
              <p style="padding-top: 5px">
                {{ calculateRequiredVehicles(offeredReservation) }}
                Required
              </p>
            </div>
          </div>
        </div>
        <div v-if="acceptedPreBookings.length" class="availability-subsection">
          <div
            class="availability-subsection--subheader justify-space-between"
            style="padding-bottom: 10px; display: flex"
          >
            <div style="display: flex">
              <v-icon :key="`link-icon`" color="black" small>link</v-icon>
              <p class="availability-subsection--subheader--text">
                Pre-Booking ({{ acceptedPreBookingsVehicleCount }})
              </p>
            </div>
            <div>
              <CRIcon
                :key="
                  acceptedPreBookingHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                "
                view-box="0 0 24 24"
                :width="24"
                :height="24"
                color="gray"
                class="arrow-icon"
                @click.native="acceptedPreBookingHidden = !acceptedPreBookingHidden"
              >
                {{
                  acceptedPreBookingHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                }}
              </CRIcon>
            </div>
          </div>
          <div v-show="!acceptedPreBookingHidden">
            <div
              v-for="acceptedPreBooking in acceptedPreBookings"
              :key="`accepted-prebooking-${acceptedPreBooking.reservationId}`"
              class="availability-subsection--reservation"
            >
              <div>
                <p class="availability-subsection--reservation--header">
                  ID
                  <router-link
                    v-if="acceptedPreBooking.parentReservationId"
                    :id="`reservation-link-${acceptedPreBooking.reservationId}`"
                    target="_blank"
                    style="text-decoration: none"
                    :to="{
                      name: 'reservation-detail',
                      params: { id: acceptedPreBooking.parentReservationId },
                    }"
                  >
                    #{{ acceptedPreBooking.managedReservationId }}
                  </router-link>
                  <span v-else>
                    #{{ acceptedPreBooking.managedReservationId }}
                  </span>
                </p>
                <p v-if="isVehicleTypeGeneralBlock" style="font-weight: 600">
                  {{ acceptedPreBooking.companyName }}
                </p>
                <p>Pickup Location: {{ acceptedPreBooking.pickupLocation }}</p>
                <p>Pickup Time: {{ acceptedPreBooking.pickupTime }}</p>
              </div>
              <p
                v-if="calculateRequiredVehicles(acceptedPreBooking)"
                style="padding-top: 5px"
              >
                {{ calculateRequiredVehicles(acceptedPreBooking) }}
                Required
              </p>
              <div
                v-if="getFilteredVehicleAssignments(acceptedPreBooking).length"
                style="padding-top: 5px"
              >
                <div
                  v-for="vehicleAssignment in getFilteredVehicleAssignments(
                    acceptedPreBooking
                  )"
                  :key="`vehicle-assignment-${vehicleAssignment.tripAssignmentId}`"
                >
                  <p style="padding-left: 10px">
                    &#x25B8; {{ vehicleAssignment.vehicle.vehicleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="acceptedReservations.length" class="availability-subsection">
          <div
            class="availability-subsection--subheader justify-space-between"
            style="padding-bottom: 10px; display: flex"
          >
            <div style="display: flex">
              <CRIcon
                :key="`check-icon`"
                view-box="0 0 24 24"
                :width="16"
                :height="16"
              >
                check
              </CRIcon>
              <p class="availability-subsection--subheader--text">
                Accepted ({{ acceptedReservationsVehicleCount }})
              </p>
            </div>
            <div>
              <CRIcon
                :key="
                  acceptedReservationHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                "
                view-box="0 0 24 24"
                :width="24"
                :height="24"
                color="gray"
                class="arrow-icon"
                @click.native="acceptedReservationHidden = !acceptedReservationHidden"
              >
                {{
                  acceptedReservationHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                }}
              </CRIcon>
            </div>
          </div>
          <div v-show="!acceptedReservationHidden">
            <div
              v-for="acceptedReservation in acceptedReservations"
              :key="`accepted-reservation-${acceptedReservation.reservationId}`"
              class="availability-subsection--reservation"
            >
              <div>
                <p class="availability-subsection--reservation--header">
                  ID
                  <router-link
                    v-if="acceptedReservation.parentReservationId"
                    :id="`reservation-link-${acceptedReservation.reservationId}`"
                    target="_blank"
                    style="text-decoration: none"
                    :to="{
                      name: 'reservation-detail',
                      params: { id: acceptedReservation.parentReservationId },
                    }"
                  >
                    #{{ acceptedReservation.managedReservationId }}
                  </router-link>
                  <span v-else>
                    #{{ acceptedReservation.managedReservationId }}
                  </span>
                </p>
                <p v-if="isVehicleTypeGeneralBlock" style="font-weight: 600">
                  {{ acceptedReservation.companyName }}
                </p>
                <p>Pickup Location: {{ acceptedReservation.pickupLocation }}</p>
                <p>Pickup Time: {{ acceptedReservation.pickupTime }}</p>
              </div>
              <p
                v-if="calculateRequiredVehicles(acceptedReservation)"
                style="padding-top: 5px"
              >
                {{ calculateRequiredVehicles(acceptedReservation) }}
                Required
              </p>
              <div
                v-if="getFilteredVehicleAssignments(acceptedReservation).length"
                style="padding-top: 5px"
              >
                <div
                  v-for="vehicleAssignment in getFilteredVehicleAssignments(
                    acceptedReservation
                  )"
                  :key="`vehicle-assignment-${vehicleAssignment.tripAssignmentId}`"
                >
                  <p style="padding-left: 10px">
                    &#x25B8; {{ vehicleAssignment.vehicle.vehicleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="sortedVehicleBlocks.length" class="availability-subsection">
          <div
            class="availability-subsection--subheader justify-space-between"
            style="display: flex"
          >
            <div style="display: flex">
              <CRIcon
                :key="`clear-icon`"
                view-box="0 0 24 24"
                :width="16"
                :height="16"
              >
                clear
              </CRIcon>
              <p class="availability-subsection--subheader--text">
                Marked Unavailable ({{ vehicleBlocks.length }})
              </p>
            </div>
            <div>
              <CRIcon
                :key="
                  vehicleBlocksHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                "
                view-box="0 0 24 24"
                :width="24"
                :height="24"
                color="gray"
                class="arrow-icon"
                @click.native="vehicleBlocksHidden = !vehicleBlocksHidden"
              >
                {{
                  vehicleBlocksHidden
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                }}
              </CRIcon>
            </div>
          </div>
          <div v-show="!vehicleBlocksHidden">
            <div
              v-for="company in sortedVehicleBlocks"
              :key="`vehicle-block-company${company.companyId}`"
            >
              <p
                v-if="isVehicleTypeGeneralBlock"
                style="
                  font-weight: 600;
                  margin-bottom: 0;
                  padding-left: 26px;
                  padding-top: 5px;
                "
              >
                {{ company.companyName }}
              </p>
              <div
                v-for="vehicleBlock in company.vehicleBlocks"
                :key="`vehicle-block-${company.companyId}-${vehicleBlock.vehicleId}`"
                class="availability-subsection--vehicle-list"
              >
                <p>
                  {{ vehicleBlock.vehicleName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    vehicleTypeId: {
      type: Number,
      default: 0,
    },
    availableVehicles: {
      type: Number,
      default: 0,
    },
    unavailableVehicles: {
      type: Number,
      default: 0,
    },
    overbookedVehicles: {
      type: Number,
      default: 0,
    },
    notOfferedVehicles: {
      type: Number,
      default: 0,
    },
    offeredReservations: {
      type: Array,
      default: () => [],
    },
    notOfferedReservations: {
      type: Array,
      default: () => [],
    },
    unassignedReservations: {
      type: Array,
      default: () => [],
    },
    assignedReservations: {
      type: Array,
      default: () => [],
    },
    vehicleBlocks: {
      type: Array,
      default: () => [],
    },
    companies: {
      type: Array,
      default: () => [],
    },
    isVehicleTypeGeneralBlock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unavailableHidden: false,
      notOfferedHidden: false,
      offeredReservationHidden: false,
      acceptedReservationHidden: false,
      acceptedPreBookingHidden: false,
      vehicleBlocksHidden: true,
    }
  },
  computed: {
    acceptedReservations() {
      let acceptedReservations = []
      acceptedReservations = acceptedReservations
        .concat(
          deepClone(this.unassignedReservations),
          deepClone(this.assignedReservations)
        )
        .filter((res) => !res.isPreBooking)
        .sort((a, b) => (a.companyName > b.companyName ? 0 : -1))
      return acceptedReservations
    },
    acceptedPreBookings() {
      let acceptedPreBookings = []
      acceptedPreBookings = acceptedPreBookings
        .concat(
          deepClone(this.unassignedReservations),
          deepClone(this.assignedReservations)
        )
        .filter((res) => res.isPreBooking)
        .sort((a, b) => (a.companyName > b.companyName ? 0 : -1))
      return acceptedPreBookings
    },
    sortedOfferedReservations() {
      return deepClone(this.offeredReservations).sort((a, b) =>
        a.companyName > b.companyName ? 0 : -1
      )
    },
    sortedVehicleBlocks() {
      return this.companies
        .filter(
          (company) =>
            this.vehicleBlocks.filter(
              (vb) => vb.companyId === company.companyId
            ).length
        )
        .map((company) => {
          return {
            companyId: company.companyId,
            companyName: company.name,
            vehicleBlocks: this.vehicleBlocks.filter(
              (vb) => vb.companyId === company.companyId
            ),
          }
        })
        .sort((a, b) => (a.companyName > b.companyName ? 0 : -1))
    },
    offeredReservationsVehicleCount() {
      let total = 0
      for (const res of this.offeredReservations) {
        for (const vehicle of res.requiredVehicles) {
          total += vehicle.quantity
        }
      }
      return total
    },
    acceptedReservationsVehicleCount() {
      let total = 0
      for (const res of this.acceptedReservations) {
        total += res.assignedVehicles.length
        for (const vehicle of res.unassignedVehicles) {
          total += vehicle.quantity
        }
      }
      return total
    },
    acceptedPreBookingsVehicleCount() {
      let total = 0
      for (const res of this.acceptedPreBookings) {
        total += res.assignedVehicles.length
        for (const vehicle of res.unassignedVehicles) {
          total += vehicle.quantity
        }
      }
      return total
    },
  },
  methods: {
    calculateRequiredVehicles(reservation) {
      const requiredVehicleQuantity = reservation.requiredVehicles.find(
        (rv) => rv.vehicleType.id === this.vehicleTypeId
      )?.quantity
      const assignedVehicleCount = reservation.vehicleAssignments.filter(
        (va) => va.vehicleTypeId === this.vehicleTypeId
      ).length
      if (requiredVehicleQuantity) {
        return requiredVehicleQuantity - assignedVehicleCount
      }
      return 0
    },
    getFilteredVehicleAssignments(reservation) {
      return reservation.vehicleAssignments.filter(
        (va) => va.vehicleTypeId === this.vehicleTypeId
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.availability-general-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    padding: 40px;
  }
}

.availability-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  &--text {
    font-size: 12px;
    padding-left: 10px;
    margin: 0;
    font-weight: 600;
  }
}

.availability-subsection {
  font-size: 10px;

  &--subheader {
    display: flex;
    align-items: center;

    &--text {
      font-size: 10px;
      padding-left: 10px;
      margin: 0;
      font-weight: 600;
    }
  }
  &--vehicle-list {
    padding-left: 26px;
    padding-top: 5px;

    p {
      margin-bottom: 0;
    }
  }
  &--reservation {
    padding-left: 26px;
    padding-bottom: 20px;

    p {
      margin-bottom: 0;
    }

    &--header {
      font-weight: 600;
      text-decoration: none !important;
    }
  }
}

.overbooked-text {
  color: $red;
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 12px;
}

.arrow-icon {
  padding-right: 4px;
  :hover {
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
